<template>
  <section >
    <pm-Breadcrumb :home="home" :model="items_bread" />
    <div class="col-md-12">
        <h2 class="titulo-links-escolha">Editar Servidor</h2>
    </div>
    <div class="card card-steps">
      <Step2 :items="items"/>
    </div>
    <br>
    <infoBasica  v-if="startComponent" @nextPage="nextPage" :pg="pg" :dadosPessoa="dadosPessoa" />

  </section >
</template>

<script>

import Step2 from '@/components/steps2.vue';
import infoBasica from '@/components/servidores/infoBasica.vue';

import vueMask from 'vue-jquery-mask';
import store_token_info from "@/store/store_token_info.js";
import { Servidores } from "@/class/servidores";
import { Funcao } from "@/class/funcao";


export default {
  props:{
    basic:{},
    id:{},
    pg:{}
  },
  components: {
   Step2,
   infoBasica
  },
  name: 'basico',
  data () {
    return {
      home: {icon: 'pi pi-home', to: '/admin/painel-adm'},
      items_bread: [
        {label: 'Editar Servidor'},
      ],
      funcaoALL: [],
      dadosPessoa:[],
      startComponent:false,
      items : [
        {
            number:'1',
            label: 'Informações básicas',
            to: "aluno-basic-info-cadastrar",
            active: 1,
        },
        {
            number:'2',
            label: 'Endereço e contato',
            to: "2",
            active: 0,
        },
        {
            number:'3',
            label: 'Confirmar',
            to: "3",
            active: 0,
        },
        /*{
            number:'1',
            label: 'Informações básicas',
            to: "aluno-basic-info-cadastrar",
            active: 1,
        },
        {
            number:'2',
            label: 'Dados de acesso ao sistema',
            to: "2",
            active: 0,
        },
        {
            number:'3',
            label: 'Endereço e contato',
            to: "3",
            active: 0,
        },
        {
            number:'4',
            label: 'Confirmar',
            to: "4",
            active: 0,
        },*/
      ],
    }
  },
  methods: {
    nextPage(info) {
      //  this.$router.push({ name: "login-dados-pessoais-servidor-editar", params: { basic: JSON.stringify(info), pg: this.pg }});
      this.$router.push({ name: "endereco-contato-servidor-editar", params: { basic: JSON.stringify(info) }});
    },
    async buscarFuncaoALL() {
      try {
      let data = await Funcao.obtemTodos();
          for (const el of data.data) {
              let novo = {
              id: el.id,
              nome:el.nome,
              };
              if (el.id  == 1 || el.id == 6)
                continue;
              this.funcaoALL.push(novo);
              this.funcaoALL.reverse();
          };
      } catch (e) {
      }
    },
  },
  async beforeMount() {
    if (this.basic != undefined) {
      let nv = JSON.parse(this.basic);
      this.dadosPessoa = nv.info;
      this.startComponent = true;
    }
    else {
        if (this.dadosPessoa.jaBuscou == undefined) {
          await this.buscarFuncaoALL();
          const data = await Servidores.obtemUm(this.id);
          let result = data.data;
          this.dadosPessoa.id = result[0].id;
          this.dadosPessoa.login_id = result[0].login_id;
          this.dadosPessoa.nome = result[0].nome;
          this.dadosPessoa.sobrenome = result[0].sobrenome;
          this.dadosPessoa.nomeMae = result[0].nome_mae;
          this.dadosPessoa.nomePai = result[0].nome_pai;
          this.dadosPessoa.observacao = result[0].observacao;
          this.dadosPessoa.email = result[0].email;
          this.dadosPessoa.certidaoNascimento = result[0].certidao_nascimento;
          this.dadosPessoa.certidaoCasamento = result[0].certidao_casamento;
          this.dadosPessoa.rg = result[0].rg;
          this.dadosPessoa.rgDataEmissao = result[0].rg_data_missao;
          this.dadosPessoa.cpf = result[0].cpf;
          this.dadosPessoa.cpfDele = result[0].cpf;
          this.dadosPessoa.dataNascimento = result[0].nascimento;
          this.dadosPessoa.sexo = result[0].sexo;
          this.dadosPessoa.matricula = result[0].matricula;
          this.dadosPessoa.observacao = result[0].observacao;
          this.dadosPessoa.possuiDeficiencia = result[0].deficiencia;
          this.dadosPessoa.deficiencia = result[0].qual_deficiencia;
          this.dadosPessoa.cep = result[0].endereco.cep;
          this.dadosPessoa.logradouro = result[0].endereco.logradouro;
          this.dadosPessoa.numero = result[0].endereco.numero;
          this.dadosPessoa.complemento = result[0].endereco.complemento;
          this.dadosPessoa.bairro = result[0].endereco.bairro;
          this.dadosPessoa.cidade = result[0].endereco.cidade;
          this.dadosPessoa.estado = result[0].endereco.estado;
          this.dadosPessoa.nee =  result[0].nee;
          this.dadosPessoa.email = result[0].login.email;
          this.dadosPessoa.emailDele = result[0].login.email;
          this.dadosPessoa.telefone = [];
          this.dadosPessoa.id = result[0].id;
          this.dadosPessoa.login_id = result[0].login_id;
          this.dadosPessoa.escolaridade = result[0].escolaridade;
          this.dadosPessoa.jaBuscou = true;

          for (const el of this.funcaoALL) {
            if (result[0].login.funcao == el.id)
              this.dadosPessoa.funcao = el;
          }
          for (const el of result[0].telefone) {
            this.dadosPessoa.telefone.push({id: el.id, nome: el.telefone, identificador: el.identificador});
          }
        }
        this.startComponent = true;

    }
  },
}
</script>

<style>
  .row.row-inside {
    max-width: none;
  }

  .form-control:focus {
    background-color: #f6f7f6;
    border-color: #673ab7;
    box-shadow: none;
  }
  .form-control {
    background-color: #f6f7f6;
    border: 1px solid #f6f6f6;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
     margin: 0;
  }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }


  .va-input-wrapper, fieldset {
    margin-bottom: 0.5rem;
  }
</style>
